@media only screen and (max-device-width: 480px) {
	.Header {
		width: 100vw;
	}

	.logo {
		padding: 0;
	}

	.home-page-link {
		display: none;
	}
}
