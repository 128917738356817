@media only screen and (max-device-width: 480px) {
    .activities {
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        flex-wrap: nowrap;
        justify-content: flex-start;
        padding: 0% 3% 0% 3%;
    }
}

.activity-button {
    color: var(--deep-purple);
    background-color: transparent;
    border: none;
    text-decoration: underline;
    margin: 0;
    padding: 0;
    font-size: 1.1;
    line-height: 120%;
}

a {
    color: var(--deep-purple);
}
