@media only screen and (max-device-width: 480px) {
	.Home {
		justify-content: flex-start;
		width: 100vw;
	}

	.content {
		width: 100%;
	}
}
