@font-face {
	font-family: 'Comfortaa';
	src: url(../fonts/Comfortaa-Regular.ttf);
}

@font-face {
	font-family: 'Comfortaa Bold';
	src: url(../fonts/Comfortaa-Bold.ttf);
	font-weight: bold;
}

@font-face {
	font-family: 'Baloo Thambi';
	src: url(../fonts/BalooThambi-Regular.ttf);
}

:root {
	--deep-purple: #3c095e;
	--plum-purple: #6c1661;
	--pink: #aa374a;
	--peach: #e8967a;
	--orange: #ffb73d;
}

* {
	padding: 0;
	margin: 0;
	font-family: 'Comfortaa';
	box-sizing: border-box;
	font-weight: bold;
	box-sizing: border-box;
}

img {
	object-fit: contain;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Baloo Thambi';
	font-size: 3.75rem;
	text-align: center;
	color: var(--plum-purple);
}

h5 {
	font-size: 1.5rem;
	text-align: left;
}

ul {
	display: flex;
	gap: 50px;
	list-style: none;
}

li {
	list-style: none;
}

a {
	text-decoration: none;
}

p {
	line-height: 2.25rem;
	font-size: 1.125rem;
}

section {
	/* padding: 200px 0; */
	margin: 0 auto;
	margin-bottom: 0;
	/* max-width: 1100px; */
	align-content: center;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
}

button {
	background-color: var(--plum-purple);
	color: white;
	font-size: 1rem;
	border: 2px solid var(--plum-purple);
	padding: 12px;
	border-radius: 20px;
	margin: 10px 10px 10px 0;
	cursor: pointer;
}

button:hover {
	background-color: white;
	color: var(--plum-purple);
	border: 2px solid var(--plum-purple);
}

.Activities {
	padding-top: 220px;
}

.About {
	margin-top: 14%;
	justify-content: center;
}

.GetStarted {
	padding-top: 100px;
}

.Interactives {
	margin-top: 100px;
	max-width: 1100px;
}

.Header {
	position: fixed;
	top: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 55px;
	height: 60px;
	background-color: #f9efed;
	border-bottom: 1px solid var(--plum-purple);
}

.nav-link {
	color: var(--plum-purple);
	font-size: 1.125rem;
	font-family: 'Baloo Thambi', sans-serif;
	letter-spacing: 1px;
}

.nav-link:hover {
	color: var(--pink);
}

.section-header {
	color: var(--plum-purple);
}

.logo {
	height: 68px;
	padding: 10px 10px 6px 8px;
}

.activities {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	padding: 0px;
	gap: 40px;
	margin: 20px 0;
}

.activity {
	background-color: #6c166123;
	border-radius: 20px;
	padding: 10px 5px 5px 5px;
	width: 200px;
	display: flex;
	flex-direction: column;
}

.activity img {
	width: 180px;
	margin: 0 auto;
	border-radius: 10px;
}

.activity h3 {
	text-align: left;
	font-size: 1.1rem;
	padding-bottom: 2px;
	text-decoration: underline;
	line-height: 120%;
	color: var(--plum-purple);
}

.activity h3 a:hover {
	color: #290641;
}

.activity p {
	padding-top: 5px;
	font-size: 0.875rem;
	font-weight: regular;
	text-align: left;
	line-height: 140%;
	border-radius: 25px;
}

.interactives {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 0px;
	gap: 40px;
	margin-top: 30px;
	width: 100%;
}

.interactive {
	background-color: #6c166123;
	border-radius: 20px;
	padding: 10px 5px 5px 5px;
	width: 200px;
	display: flex;
	flex-direction: column;
}

.interactive img {
	width: 180px;
	margin: 0 auto;
	border-radius: 10px;
}

.interactive h3 {
	text-align: left;
	font-size: 1.1rem;
	padding-bottom: 2px;
	text-decoration: underline;
	line-height: 120%;
	color: var(--plum-purple);
}

.interactive h3 a:hover {
	color: #290641;
}

.interactive p {
	padding-top: 5px;
	font-size: 0.875rem;
	font-weight: regular;
	text-align: left;
	line-height: 140%;
	border-radius: 25px;
}

.caption {
	padding: 10px;
	text-align: left;
}

.icon {
	height: 14px;
	margin-left: 5px;
}

.two-sides {
	margin: 200px 20px 0 20px;
	gap: 20px;
	display: flex;
	width: 100%;
	flex-direction: row;
	flex-wrap: wrap-reverse;
	justify-content: center;
	/* justify-content: space-between; */
}

.two-sides div {
	max-width: 600px;
}

.two-sides img {
	max-width: 90%;
}

.text-left {
	text-align: left;
}

.video {
	margin: 30px auto;
}

iframe {
	display: block;
	border: none;
}

.Footer {
	background-color: #f9efed;
	padding-top: 90px;
	padding-bottom: 10px;
	border-top: 1px solid var(--plum-purple);
	bottom: 0;
}

.footer-content {
	max-width: 1100px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
}
.footer-logo {
	height: 100px;
}

.footer-icon {
	height: 30px;
}

.media {
	display: flex;
	flex-direction: row;
	gap: 20px;
	width: 320px;
}

.media-icons {
	display: flex;
	flex-direction: column;
	justify-content: right;
	flex-wrap: wrap;
	gap: 8px;
	height: 250px;
}

.media-icons a:hover {
	transform: scale(1.1);
}

.center-text {
	text-align: center;
	padding-bottom: 20px;
}

.welcome-illustration {
	object-fit: cover;
	border-radius: 25px;
}

.background {
	position: absolute;
	width: 100%;
	z-index: -2;
	object-fit: fill;
	background-repeat: no-repeat;
	background-size: 100%;
	overflow: hidden;
}

#About-Alchemie {
	background-image: url('./images/orange-background.svg');
}

.more-activities-button {
	display: flex;
	flex-direction: column;
	background-color: #6c166123;
	border-radius: 20px;
	padding: 10px 5px 5px 5px;
	width: 200px;
	display: flex;
	flex-direction: column;
	height: 218px;
	align-items: center;
	justify-content: space-evenly;
}
.more-activities-button h3 {
	text-align: left;
	font-size: 1.1rem;
	padding-bottom: 2px;
	text-decoration: underline;
	line-height: 120%;
}

.more-activities-button h3 a:hover {
	color: #290641;
}
.more-activities-button p {
	padding-top: 5px;
	font-size: 0.875rem;
	font-weight: regular;
	text-align: left;
	line-height: 140%;
	border-radius: 25px;
}

#flask {
	color: #551a8b;
}

.two-sides {
	display: flex;
}

@media only screen and (max-device-width: 480px) {
	/* Landing section */

	.landing-right {
		display: none;
	}

	li {
		overflow: hidden;
		white-space: nowrap;
	}

	ul {
		gap: 1%;
	}

	.Header {
		width: 100%;
		padding: 0;
	}

	.home-text {
		text-align: center;
	}

	.home-buttons {
		display: flex;
		justify-content: center;
	}

	.text-left {
		text-align: center;
	}

	.two-sides {
		display: flex;
		flex-direction: column;
	}

	/* footer content */

	.Footer {
		margin-top: 0;
		padding-top: 10%;
		border-top: 1px solid #3c095e;
	}

	.media-icons {
		width: 100vw;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		gap: 10%;
		padding-bottom: 0;
		height: 50%;
		justify-content: start;
	}

	.media-icons a {
		height: 65px;
	}

	.footer-content {
		display: flex;
		flex-direction: column-reverse;
	}

	.media {
		display: flex;
		flex-direction: column;
	}
}
